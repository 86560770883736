@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom components */
.card {
  @apply rounded overflow-hidden shadow-lg;
}

.card-body {
  @apply px-6 py-4;
}

.form-control {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.form-control:focus {
  @apply outline-none shadow-md border-blue-400 border-2;
}

.button {
  @apply bg-blue-500  text-white font-bold py-2 px-4 rounded;
}

.button:hover {
  @apply bg-blue-700;
}

.button:focus {
  @apply outline-none shadow-md;
}
